import $ from 'jquery';

function modalSymbolsSummary() {

  if ($('#js-accessAbilitySymbols').length > 0) {

    var ajaxURL = "";
    $('.js-accessAbilitySymbols__modalTrigger').click(function(evt){
      evt.preventDefault();
      ajaxURL = evt.currentTarget.dataset.url;
    });

    $(document).on('open.zf.reveal', "#js-accessAbilitySymbols", function (e) {
      var $modal = $(this);
      var $content = $('.js-accessAbilitySymbols__content');
      if (ajaxURL) {
        $content.html("Loading...");
        $.ajax(ajaxURL).done(function (response) {
          $content.html(response);
        });
      }
    });
  }
}

export default modalSymbolsSummary;
