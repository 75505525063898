// Scroll indicator
// We're using this to show a custom shadow if there are more items that can be seen on scroll
const scrollIndicatorSelector = '.js-scrollIndicator';

const scrollIndicator = () => {

  // Loop all of the instances of the hook
  document.querySelectorAll(scrollIndicatorSelector).forEach((scrollIndicatorHook => {

    const scrollArea = scrollIndicatorHook.querySelector('.c-scrollIndicator__main');

    _checkScroll(false, scrollArea);
    scrollArea.addEventListener('scroll', _checkScroll);
  }));
};

// Private: Our main method to check the elements scroll position etc.
const _checkScroll = (event, scrollArea) => {

  // Nicer way to reference the selector
  scrollArea = event ? event.target : scrollArea;

  // Make sure the selector is acually overflowed
  if (scrollArea.clientHeight > scrollArea.scrollHeight) return;

  const scrollOffsetByHeight = scrollArea.scrollTop + scrollArea.clientHeight;
  const isScrolledToBottom = (scrollOffsetByHeight >= scrollArea.scrollHeight);

  if (isScrolledToBottom) {
    scrollArea.closest(scrollIndicatorSelector).classList.remove('is-scrollable');
  }
  else {
    scrollArea.closest(scrollIndicatorSelector).classList.add('is-scrollable');
  }
};

export default scrollIndicator;
