import $ from 'jquery';
import Mark from 'mark.js/dist/jquery.mark.js';

function accessGuideSearch() {

  const sections = document.querySelectorAll(".c-accessGuide__tab");
  const tabs = document.querySelectorAll(".c-accessGuide .tabs-title");
  const keywordInput = document.querySelector(".js-accessGuideSearchInput");
  const searchButton = document.querySelector(".c-accessGuide__searchIcon");

  function performMark() {
    const term = keywordInput.value;

    tabs.forEach((tab) => {
      let tabId = tab.querySelector("a").getAttribute('href');
      const options = {
        "ignoreJoiners": true,
        done: function(counter){
          if(counter > 0 && term.length >= 3) {
            $(tab).find('.js-searchCount').removeClass('hide');
            $(tab).find('.js-searchCount').html(counter);
          } else {
            $(tab).find('.js-searchCount').addClass('hide');
          }
        }
      };

      $(tabId).unmark();
      $(tabId).mark(term, options);
    });

    // show clear/cross icon if input has a value
    if(term.length > 0) {
      searchButton.classList.add('is-active')
    } else {
      searchButton.classList.remove('is-active')
    }

  };

  function clear() {
    keywordInput.value = '';
    searchButton.classList.remove('is-active');
    $(".c-accessGuide__tab").unmark();
    $('.js-searchCount').addClass('hide');
  }

  if(sections.length > 0) {

    keywordInput.addEventListener("input", performMark);
    searchButton.addEventListener("click", clear);

  }
}

export default accessGuideSearch;
