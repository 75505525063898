import $ from 'jquery';

function accessGuideTabFocus() {

  let listCount = $('#guide_tabs li').length;

  if(listCount > 0) {
    const $tabLinks = $("#guide_tabs a");
    const $sectionGallery = $('.js-sectionGallery');
    $tabLinks.on("click", event => {
      $sectionGallery.slick('refresh');
      event.preventDefault();
      const hash = event.currentTarget.hash;
      window.location.hash = hash;
    });

    $tabLinks.on("focus", event => {
      $sectionGallery.slick('refresh');
      const hash = event.currentTarget.hash;
      window.location.hash = hash;
      event.currentTarget.focus();
    });

    let jumpLink = $('.js-accessGuideJumpLink');

    jumpLink.on('click', function(evt) {
      evt.preventDefault();

      let itemId = $(this).parent().attr('id');
      let itemIdNumber = itemId.split("_").pop();
      let nextItemNumber = parseInt(itemIdNumber) + 1;
      let tab = "#tab_" + nextItemNumber;
      let tabLabel = "#tab_" + nextItemNumber + "-label";

      $(tabLabel).focus();

      if(nextItemNumber < listCount) {
        $("#guide_tabs").foundation("selectTab", $(tab));
      } else {
        $("#guide_tabs").foundation("selectTab", "#tab_0");
      }
    });
  }
}

export default accessGuideTabFocus;
