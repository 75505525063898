import $ from 'jquery';
import objectFitPolyfill from "objectFitPolyfill";

function objectFit() {
  var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
  if(isIE11) {
    if ( $('img').data('object-fit') ) {
      objectFitPolyfill();
    }
  }
}

export default objectFit;
