import $ from 'jquery';

function targetAccordion(target) {
  var parent = $(target).data('parent');
  return parent !== undefined
    ? $(`#${parent}`)
    : $(target).parent().parent().find('.js-accordion');
}

function applyAccordionAction(target, action) {
  var accordion = targetAccordion(target);
  accordion.foundation(action, accordion.find('.accordion-content'));
}

function accordion() {

  $('.js-accordion-close').on('click', function () {
    applyAccordionAction(this, 'up');
  })
  $('.js-accordion-open').on('click', function () {
    applyAccordionAction(this, 'down');;
  })

}

export default accordion;
