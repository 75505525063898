import $ from 'jquery';

function accordionTabs() {
  var viewportWidth = $(window).width();
  $(window).on('resize', function () {
    if ( $(this).width() != viewportWidth) {
      viewportWidth = $(this).width();
    }
  });
  $('.c-accordionTabs__title').on('click', function () {
    if (viewportWidth < 1024 ) {
      $(this).parent().toggleClass('is-active');
    }
  });

}

export default accordionTabs;
