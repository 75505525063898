const _globals = {
  zoomControlHook: 'js-zoomMap'
};

const zoomControls = (container = '.js-map', mapInstance) => {

  const $container = document.querySelector(container);

  if (!$container) return;

  const zoomControl = direction => {
    return `<a class="c-zoomControls__control  c-zoomControls__control--${direction}  ${_globals.zoomControlHook}" data-zoom-direction="${direction}"></a>`;
  };

  const html = document.createElement('nav');
  html.classList.add('c-zoomControls');
  html.innerHTML = zoomControl('in');
  html.innerHTML += zoomControl('out');

  $container.appendChild(html);

  const $zoomControls = $container.getElementsByClassName(_globals.zoomControlHook);

  [...$zoomControls].forEach($zoomControl => {

    $zoomControl.addEventListener('click', () =>
      _zoomMap(event.target.dataset.zoomDirection, mapInstance)
    );

  });

};

const _zoomMap = (direction, mapInstance) => {

  if (!direction) return;

  const newZoom = (direction === 'in') ? mapInstance.getZoom() + 1 : mapInstance.getZoom() - 1;
  mapInstance.setZoom(newZoom);

};

export default zoomControls;
