/* global google, InfoBubble, Foundation */
import $                          from 'jquery';
import loadGoogleMapsApi          from 'load-google-maps-api';
import mapOptions                 from './options';
import zoomControls               from './zoom_controls';
import MarkerClusterer            from '@google/markerclustererplus';
import appendScript               from './append_script';

import defaultMarkerImage         from 'images/markers/default@2x.png';
import activeMarkerImage          from 'images/markers/default-active@2x.png';
import defaultClusterMarkerImage  from 'images/markers/cluster-default.png';
import activeClusterMarkerImage   from 'images/markers/cluster-active.png';
import scrollIndicator            from '../scroll_indicator';

const _globals = {
  locationSelector: '.c-listingCard',
  locationQuerySelector: '#location_query',
  mapSelector: '.js-map',
  mapOps: mapOptions,
  api: {
    key: 'AIzaSyCQnfrDrya4bZRzCn4EfBimwPDcbvGh2fo',
    libraries: ['geometry']
  },
  clusterOps: {
    modalId: 'cluster_modal',
    gridSize: 44,
    zoomTo: 14,
    maxZoom: 0,
    zoomOnClick: false,
    styles: [
      {
        textColor: '#fff',
        textSize: 14,
        url: defaultClusterMarkerImage,
        width: 38,
        height: 44
      }
    ]
  },
  infoBubbleOps: {
    shadowStyle: 1,
    padding: 0,
    backgroundColor: 'rgb(255,255,255)',
    borderRadius: 4,
    arrowSize: 10,
    borderWidth: 0,
    minHeight: 'auto',
    maxHeight: 'auto',
    minWidth: 'auto',
    maxWidth: 'auto',
    disableAutoPan: true,
    hideCloseButton: true,
    arrowPosition: 30,
    arrowStyle: 0
  }
};

/**
 * Build our location data.
 *
 * @param {String} [locationSelector=_globals.locationSelector] Selector for the DOM elements holding location data in data-attributes.
 * @returns {Array} An array of objects holding location data
 */
const _createLocData = (locationSelector = _globals.locationSelector) => {

  let locations = [];

  const $locationSelector = document.querySelectorAll(locationSelector);

  [...$locationSelector].map(($locCard) => {

    if (!$locCard.dataset.lat) return;

    const thisLocation = {
      title:    $locCard.dataset.title,
      lat:      $locCard.dataset.lat,
      lng:      $locCard.dataset.lng,
      link:     $locCard.dataset.link,
      type:     $locCard.dataset.type,
      image:    $locCard.dataset.image,
      address:  $locCard.dataset.address,
      element:  $locCard
    };

    locations.push(thisLocation);

  });

  return locations;


};


/**
 * Open the info bubble for a marker.
 *
 * @param {Object} infoBubble
 * @param {Array} locs
 * @param {Object} map
 * @param {Object} marker
 */
const _openInfoBubble = (infoBubble, locs, map, marker) => {

  let items = locs.map((loc) => {
    return `
      <a class="c-infoBubble__item" href="${loc.link}">
        <div>
          <h1 class="c-infoBubble__title">${loc.title}</h1>
          <span class="o-icon c-button c-button--outline c-button--outlinePrimary c-infoBubble__button">View ${loc.type}</span>
        </div>
      </a>
    `;
  });

  items = items.join('');

  const closeButton = `
    <a class="c-infoBubble__close js-closeInfoBubble" href="#">
      <svg width="12" height="12">
        <use xlink:href="/packs/sprite.svg#close"></use>
      </svg>
    </a>
  `;

  const html = `
    <div class="c-infoBubble c-scrollIndicator js-scrollIndicator">
      ${closeButton}
      <div class="c-infoBubble__main c-scrollIndicator__main">
        ${items}
      </div>
      <figure class="c-scrollIndicator__shadow"></figure>
    </div>
  `;

  infoBubble.setContent(html);
  infoBubble.open(map, marker);

  const afterInfoBubbleOpen = () => {

    scrollIndicator();

    const $close = document.querySelector('.js-closeInfoBubble');

    if ($close) {

      $close.addEventListener('click', event => {
        event.preventDefault();
        infoBubble.close();
      });

    }

    if (infoBubble.isOpen()) clearInterval(isOpen);
  };

  const isOpen = setInterval(afterInfoBubbleOpen, 100);

};


/**
 * Add Markers to Google Maps.
 *
 * @param {Array} locations
 * @param {Object} map
 */
const _addMarkers = (locations, map) => {

  if (!locations.length) {
    const searchLoc = _createSearchQueryLatLng();
    map.setZoom(6);
    map.setCenter(searchLoc.position);
  }

  const defaultMarker = {
    url: defaultMarkerImage,
    scaledSize: new google.maps.Size(45, 50)
  };

  const activeMarker = {
    url: activeMarkerImage,
    scaledSize: new google.maps.Size(45, 50)
  };

  const markerMouseover = marker => {
    marker.loc.element.classList.add('is-hovered');
    marker.setIcon(activeMarker);
  };

  const markerMouseout = marker => {
    marker.loc.element.classList.remove('is-hovered');
    marker.setIcon(defaultMarker);
  };

  const bounds = new google.maps.LatLngBounds();

  const infoBubble = new InfoBubble(_globals.infoBubbleOps);

  const markers = [...locations].map((loc) => {

    let marker = new google.maps.Marker({
      size: new google.maps.Size(45, 50),
      icon: defaultMarker,
      position: new google.maps.LatLng(loc.lat, loc.lng),
      map: map,
      bounds: new google.maps.LatLngBounds(),
      loc: loc
    });

    bounds.extend(marker.position);

    map.fitBounds(bounds);

    // Events
    // ======

    // Bind mouseover
    if (loc.element) {
      google.maps.event.addListener(marker, 'mouseover', () => markerMouseover(marker));
      loc.element.addEventListener('mouseenter', () => markerMouseover(marker));

      // Bind mouseout
      google.maps.event.addListener(marker, 'mouseout', () => markerMouseout(marker));
      loc.element.addEventListener('mouseleave', () => markerMouseout(marker));

      loc.element.addEventListener('click', () => {

        marker.bounds.extend(marker.position);
        map.fitBounds(marker.bounds);
        map.setZoom(map.getZoom() - 6);
        map.panToBounds(marker.bounds);

        _openInfoBubble(infoBubble, [loc], map, marker);
      });
    }

    // Bind click
    google.maps.event.addListener(marker, 'click', () => {

      _openInfoBubble(infoBubble, [loc], map, marker);

      if (loc.element) {
        $('.o-map__results .c-scrollIndicator__main').animate({
          scrollTop: loc.element.offsetTop
        }, 600);
      }

    });

    return marker;

  });

  // Marker Clustering
  // =================
  const markerCluster = new MarkerClusterer(map, markers, _globals.clusterOps);

  const _setClusterImage = (event, cluster) => {

    // Select this specific cluster using the private methods
    // This is a bit of a hack, see: https://stackoverflow.com/questions/20454582/marker-clusterer-plus-change-icon-on-hover
    const $cluster = cluster.clusterIcon_.div_;
    if ($cluster.firstChild) {
      $cluster.firstChild.src = (event.type === 'mouseenter') ? activeClusterMarkerImage : _globals.clusterOps.styles[0].url;
    }
  };

  const cardMouseoverclusterMarker = (event, marker) => {
    markerCluster.getClusters().forEach((cluster) => {
      if (cluster.getMarkers().includes(marker)) _setClusterImage(event, cluster);
    });
  };

  // Cluster hover events
  google.maps.event.addListener(markerCluster, 'mouseover', cluster => {

    // google.maps.event.addListener handler doesn't return event (it only returns the instance), so we create our own event
    const event = { type: 'mouseenter' };
    _setClusterImage(event, cluster);
  });

  google.maps.event.addListener(markerCluster, 'mouseout', cluster => _setClusterImage(false, cluster));

  // Card hover events
  markers.forEach((marker) => {
    marker.loc.element.addEventListener('mouseenter', event => cardMouseoverclusterMarker(event, marker));
    marker.loc.element.addEventListener('mouseleave', event => cardMouseoverclusterMarker(event, marker));
  });

  google.maps.event.addListener(markerCluster, 'clusterclick', cluster => {

    markers.forEach((marker) => markerMouseout(marker));

    if (map.getZoom() < _globals.clusterOps.zoomTo) {

      markerCluster.zoomOnClick = true;
      map.fitBounds(cluster.getBounds());

    } else {

      let locs = [];

      cluster.getMarkers().forEach((marker) => {
        locs.push(marker.loc);
      });

      _openModal(locs);
    }

  });

  // Cluster Specific Events
  google.maps.event.addListener(markerCluster, 'mouseover', cluster => {
    cluster.getMarkers().forEach((marker) => {
      markerMouseover(marker);
    });
  });

  google.maps.event.addListener(markerCluster, 'mouseout', cluster => {
    cluster.getMarkers().forEach((marker) => {
      markerMouseout(marker);
    });
  });

};


/**
 * Core function to build search results.
 */
const searchResults = () => {

  if (!document.querySelector(_globals.mapSelector)) return;

  loadGoogleMapsApi(_globals.api).then(googleMaps => {

    window.google = {};
    window.google.maps = googleMaps;

    appendScript.url('/infobubble-compiled.js').then(_createMap)
      .catch(error => {
        console.error(error);
      });

  }).catch(error => {
    console.error(error);
  });

};


const _createSearchQueryLatLng = (locationQuerySelector = _globals.locationQuerySelector) => {

  const $locationQuery = document.querySelector(locationQuerySelector);

  if (!$locationQuery || !$locationQuery.dataset.lat) return;

  const searchLoc = {
    lat: parseFloat($locationQuery.dataset.lat),
    lng: parseFloat($locationQuery.dataset.lng)
  };

  searchLoc.position = new google.maps.LatLng(searchLoc.lat, searchLoc.lng);

  return searchLoc;
};


/**
 * Calculate distance from the location input field to the results.
 *
 * @param {String} [locationSelector=_globals.locationSelector]
 */
const _calculateDistance = (locationSelector = _globals.locationSelector, locationQuerySelector = _globals.locationQuerySelector) => {

  const searchLoc = _createSearchQueryLatLng();

  const $locationQuery = document.querySelector(locationQuerySelector);
  if (!$locationQuery.value) return;

  const $locationSelector = document.querySelectorAll(locationSelector);

  [...$locationSelector].map(($locCard) => {

    const loc = {
      lat: parseFloat($locCard.dataset.lat),
      lng: parseFloat($locCard.dataset.lng)
    };

    loc.position = new google.maps.LatLng(loc.lat, loc.lng);

    const distance = google.maps.geometry.spherical.computeDistanceBetween(loc.position, searchLoc.position);

    $locCard.querySelector('.js-location_radius').innerText = 'Distance: ' + (distance/1609.34).toFixed(2) + ' miles';
  });

};


const _createModal = (modalId = _globals.clusterOps.modalId) => {

  if (document.getElementById(modalId)) document.getElementById(modalId).parentNode.remove();

  const $modal = document.createElement('aside');
  $modal.classList.add('reveal', 'c-modal');
  $modal.id = modalId;
  $modal.setAttribute('aria-labelledby', `${modalId}_title`);
  $modal.innerHTML = `
    <div class="c-modal__contentWrapper">
      <section class="js-modalContent"></section>
      <button class="close-button c-modal__closeBtn" data-close aria-label="Close modal" type="button">
        <svg aria-hidden="true" class="c-modal__closeIcon">
          <use xlink:href="/packs/sprite.svg#close"></use>
        </svg>
      </button>
    </div>
  `;

  document.body.appendChild($modal);
};


const _addModalContent = (results, modalId = _globals.clusterOps.modalId) => {

  const $modal = document.getElementById(modalId);
  const $target = $modal.querySelector('.js-modalContent');

  let items = results.map((result) => {
    return `
      <article class="c-listingCard grid-x grid-padding-x grid-padding-y align-middle">
        <figure class="c-listingCard__imageWrap cell shrink">
          <img class="c-listingCard__image" src="${result.image}" alt="Image of ${result.title}">
        </figure>
        <div class="c-listingCard__main cell auto">
          <div class="grid-x">
            <div class="cell auto">
              <h1 class="c-listingCard__title">${result.title}</h1>
              <address class="c-listingCard__address">${result.address}</address>
            </div>
            <div class="cell shrink">
              <a class="o-icon c-button c-button--outline c-button--outlinePrimary c-button--small" href="${result.link}">
                <svg width="16" height="16">
                  <use xlink:href="/packs/sprite.svg#accessability-guide"></use>
                </svg>
                <span>View ${result.type}</span>
              </a>
            </div>
          </div>
        </div>
      </article>
    `;
  });

  const title = `<h1 id="${modalId}_title" class="c-modal__title u-h3">Showing ${items.length} results at this location</h1>`;

  items = items.join('');
  $target.innerHTML = title + items;
};


const _openModal = (content, modalId = _globals.clusterOps.modalId) => {

  _createModal(modalId);
  _addModalContent(content, modalId);

  const $modal = $('#' + modalId);
  const modal = new Foundation.Reveal($modal);
  modal.open();
};

/**
 * Core function to build search results, initialised as a callback when Google Maps API is loaded.
 */
const _createMap = () => {

  const map = new google.maps.Map(document.querySelector(_globals.mapSelector), _globals.mapOps);
  const locations = _createLocData();
  _addMarkers(locations, map);
  zoomControls(_globals.mapSelector, map);
  _calculateDistance();
};

export default searchResults;
