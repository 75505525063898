import $ from 'jquery';

const _globals = {
  selectorHook: '.js-uncheckAll'
};


/**
 * Main function used to capture click events on our hook
 *
 * @param {String} [selector=_globals.selectorHook] Selector to hook to for our event listener
 */
const uncheckAllListener = (selector = _globals.selectorHook) => {
  $(selector).click(event => _uncheckAll(event, event.target.form));
};


/**
 * Uncheck all checkoxes event.
 *
 * @private
 * @param {Event} event Captured to preventDefault();
 * @param {Object} form Form node, used to scope selection of checkboxes
 */
const _uncheckAll = (event, form) => {

  // Run preventDefault() if applicable
  if (typeof event !== 'undefined') event.preventDefault();

  // Select all checkboxes within the form
  const $checkboxes = form.querySelectorAll('input[type="checkbox"]');

  // Uncheck all the items
  $checkboxes.forEach($checkbox => $checkbox.checked = false);
};

export default uncheckAllListener;
