/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// ES6 Promise polyfill
import 'promise-polyfill/src/polyfill';
import 'nodelist-foreach-polyfill';

// Styles
import '../../../stylesheets/core.scss';

// Assets
import files                    from '../modules/svg-sprite';

// JS Modules
import Rails                    from '@rails/ujs';
import $                        from 'jquery';
import foundation               from 'foundation-sites';
import svg4everybody            from 'svg4everybody';

import mobilePrimaryNavigation  from '../modules/mobile_primary_navigation';
import searchAutoComplete       from '../modules/search_auto_complete';
import promotionsCarousel       from '../modules/promotions_carousel';
import partnersCarousel         from '../modules/partners_carousel';
import featuredCarousel         from '../modules/featured_carousel';
import mediaTabs                from '../modules/media_tabs';
import scrollIndicator          from '../modules/scroll_indicator';
import capHeight                from '../modules/cap_height';
import modalSymbolsSummary      from '../modules/modal_symbols_summary';
import jumpNav                  from '../modules/jump_nav';
import accordion                from '../modules/accordion';
import accordionTabs            from '../modules/accordion_tabs';
import searchResults            from '../modules/maps/search_results';
import uncheckAllListener       from '../modules/uncheck_all';
import searchFilters            from '../modules/search_filters';
import objectFit                from '../modules/object_fit';
import easyRead                 from '../modules/easy_read';
import newTab                   from '../modules/new_tab';
import accessGuideSearch        from '../modules/access_guide_search';
import accessGuideTabFocus      from '../modules/access_guide_tab_focus';
import mobileDetection          from '../modules/mobile_detection';
import recaptchaEnter           from '../modules/recaptcha';
import panoramaPopupModal       from '../modules/panorama_popup_modal';
import imagePopupModal          from '../modules/image_popup_modal';

const init = () => {
  Rails.start();
  svg4everybody();
  mobilePrimaryNavigation();
  searchAutoComplete();
  promotionsCarousel();
  partnersCarousel();
  featuredCarousel();
  modalSymbolsSummary();
  mediaTabs();
  searchResults();
  searchFilters();
  scrollIndicator();
  capHeight();
  jumpNav();
  accordion();
  accordionTabs();
  uncheckAllListener();
  objectFit();
  easyRead();
  newTab();
  accessGuideSearch();
  accessGuideTabFocus();
  mobileDetection();
  recaptchaEnter();
  panoramaPopupModal();
  imagePopupModal();
};

$(() => {
  foundation.addToJquery($);
  $(document).foundation();
  init();
});
