import $ from 'jquery';
import slick from 'slick-carousel';

function partnersCarousel() {
  var slider = $('.js-partnersCarousel');

  slider.slick({
    dots: false,
    slidesToShow: 6,
    slidesToScroll: 6,
    infinite: false,
    responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
  ]
  });

}

export default partnersCarousel;
