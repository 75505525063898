import $ from 'jquery';
import Bloodhound   from '../modules/typeahead/bloodhound';
import typeahead    from '../modules/typeahead/typeahead.bundle';

function searchAutoComplete() {
  
  var results = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.whitespace,
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
      url: '/searches/autocomplete?query=%QUERY',
      wildcard: '%QUERY'
    }
  });
  $('.js-search').typeahead({
    hint: false,
    highlight: false,
    minLength: 3,
    autoselect: false
  },
  {
    source: results,
    limit: 20
  });

}

export default searchAutoComplete;
