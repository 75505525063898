import $            from 'jquery';
import onePageNav   from '../modules/one_page_nav';
import tabbable     from 'tabbable';


/**
 * Add tab listener
 *
 * @param {String} [hash=document.activeElement.hash] Target hash to seach within for tabbable content.
 * @param {String} [source=_settings.jumpNavSelector] Source element to listen & bind events to.
 */
const _addTabListener = (hash = document.activeElement.hash, source = _settings.jumpNavSelector) => {

  // Get our selector
  const $target = document.querySelector(hash);

  $(source)
    .unbind('keydown') // Unbind any previously attached keydown listeners
    .on('keydown', (event) => {

      // Determine the keycode
      const keyCode = event.keyCode || event.which;

      // Return if the keycode isn't 9 (Tab)
      if (!keyCode === 9) return;

      event.preventDefault();
      tabbable($target)[0].focus(); // First tabbable element within the selector
      $(_settings.jumpNavSelector).unbind('keydown'); // Remove our listener so we no longer override tab behaviour
    });

};


/**
 * Global Settings
 */
const _settings = {
  jumpNavSelector: '.js-jumpNav',
  onePageNavOps: {
    begin: _addTabListener
  }
};


/**
 * Jump Nav
 *
 * @param {String} [source=_settings.jumpNavSelector] Selector to attach onePageNav to.
 * @param {Object} [ops=_settings.onePageNavOps] onePageNav options object containing all configuration.
 */
const jumpNav = (source = _settings.jumpNavSelector, ops = _settings.onePageNavOps) => {

  $(source).onePageNav(ops);

};

export default jumpNav;
