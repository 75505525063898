function newTab() {
  const accessGuideSectionLinks = document.querySelectorAll('.c-accessGuide__tab a');

  if (accessGuideSectionLinks.length > 0) {

    for (var i=0; i < accessGuideSectionLinks.length; i++) {
      accessGuideSectionLinks[i].setAttribute("target", "_blank");
    }
  }
}

export default newTab;
