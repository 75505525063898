import $ from 'jquery';
import slick from 'slick-carousel';
import arrows from './sliders/arrows';

function featuredCarousel() {

  var slider = $('.js-featuredCarousel');

  slider.slick({
    ...arrows,
    dots: false,
    slidesToShow: 3,
    rows: 0,
    infinite: false,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2
        }
      },
    ]
  });

}

export default featuredCarousel;
