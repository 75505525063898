const _styles = [
  {
    'featureType': 'administrative.country',
    'elementType': 'geometry.stroke',
    'stylers': [
      {
        'color': '#918ea8'
      }
    ]
  },
  {
    'featureType': 'administrative.country',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#452062'
      }
    ]
  },
  {
    'featureType': 'administrative.country',
    'elementType': 'labels.text.stroke',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'administrative.province',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#999999'
      }
    ]
  },
  {
    'featureType': 'administrative.province',
    'elementType': 'labels.text.stroke',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'administrative.locality',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#666666'
      }
    ]
  },
  {
    'featureType': 'administrative.locality',
    'elementType': 'labels.text.stroke',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'administrative.neighborhood',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#999999'
      },
      {
        'saturation': '0'
      }
    ]
  },
  {
    'featureType': 'landscape',
    'elementType': 'geometry.fill',
    'stylers': [
      {
        'saturation': '-22'
      },
      {
        'lightness': '25'
      }
    ]
  },
  {
    'featureType': 'landscape.man_made',
    'elementType': 'geometry.fill',
    'stylers': [
      {
        'color': '#ecf0f4'
      }
    ]
  },
  {
    'featureType': 'poi',
    'elementType': 'geometry',
    'stylers': [
      {
        'saturation': '-50'
      },
      {
        'lightness': '50'
      }
    ]
  },
  {
    'featureType': 'poi',
    'elementType': 'labels.text.stroke',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'poi',
    'elementType': 'labels.icon',
    'stylers': [
      {
        'saturation': '70'
      }
    ]
  },
  {
    'featureType': 'poi.attraction',
    'elementType': 'labels',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'poi.business',
    'elementType': 'labels',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'poi.government',
    'elementType': 'labels',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'poi.medical',
    'elementType': 'all',
    'stylers': [
      {
        'visibility': 'on'
      }
    ]
  },
  {
    'featureType': 'poi.medical',
    'elementType': 'geometry.stroke',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'poi.medical',
    'elementType': 'labels.text',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'poi.medical',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#452062'
      }
    ]
  },
  {
    'featureType': 'poi.park',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'saturation': '25'
      },
      {
        'lightness': '10'
      }
    ]
  },
  {
    'featureType': 'poi.park',
    'elementType': 'labels.text.stroke',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'poi.place_of_worship',
    'elementType': 'labels',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'poi.school',
    'elementType': 'labels',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'poi.sports_complex',
    'elementType': 'geometry.fill',
    'stylers': [
      {
        'saturation': '-43'
      },
      {
        'lightness': '31'
      }
    ]
  },
  {
    'featureType': 'poi.sports_complex',
    'elementType': 'geometry.stroke',
    'stylers': [
      {
        'saturation': '-23'
      },
      {
        'lightness': '71'
      }
    ]
  },
  {
    'featureType': 'poi.sports_complex',
    'elementType': 'labels',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'road',
    'elementType': 'labels.icon',
    'stylers': [
      {
        'saturation': '70'
      }
    ]
  },
  {
    'featureType': 'road.highway',
    'elementType': 'geometry.fill',
    'stylers': [
      {
        'color': '#25e4a0'
      }
    ]
  },
  {
    'featureType': 'road.highway',
    'elementType': 'geometry.stroke',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'road.highway',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#333333'
      }
    ]
  },
  {
    'featureType': 'transit',
    'elementType': 'labels.text',
    'stylers': [
      {
        'saturation': '31'
      }
    ]
  },
  {
    'featureType': 'water',
    'elementType': 'geometry.fill',
    'stylers': [
      {
        'color': '#a4c0f5'
      }
    ]
  }
];

const mapOptions = {
  styles: _styles,
  disableDefaultUI: true
};

export default mapOptions;
