const appendScript = {

  /**
   * Append an external JavaScript resource.
   *
   * @param {String} url Remote URL Path for the JavaScript.
   * @returns A JavaScript promise.
   */
  url: (url) => {

    const scriptId = url.split('/').pop().slice(0, -3);

    if (!document.getElementById(scriptId) === undefined) return;

    return new Promise((resolve, reject) => {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.id = scriptId;
      script.addEventListener('load', () => resolve(script), false);
      script.addEventListener('error', () => reject(script), false);
      document.getElementsByTagName('head')[0].appendChild(script);
    });

  },
  /**
   * Append external JavaScript resources.
   *
   * @param {Array} urls An array of the remote URL Path's for the JavaScript.
   * @returns A promise for each resource loaded.
   */
  urls: (urls) => {
    return Promise.all(urls.map(appendScript.url));
  }
};

export default appendScript;
