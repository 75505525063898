function mobileDetection() {
  const userAgent = window.navigator.userAgent;
  const mobileAlert = sessionStorage.getItem("mobileAlert");

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {

    const popupInfo = {
      storeType: '',
      appLink: '',
    }

    // Only show the pop-up once per session
    if (mobileAlert) { return null; }

    // if (/android/i.test(userAgent)) {
    //   sessionStorage.setItem("mobileAlert", true);

    //   popupInfo.storeType = "Play";
    //   popupInfo.appLink = "https://play.google.com/store/apps/details?id=com.accessable";
    // }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      sessionStorage.setItem("mobileAlert", true);

      popupInfo.storeType = "App";
      popupInfo.appLink = "https://itunes.apple.com/gb/app/accessable/id1433928170";
    }

    const popupMarkup = `
      <div class="c-mobileAppPopup">
        <div class="grid-x grid-padding-x align-middle">
          <div class="small-6 cell">
            <h3 class="c-mobileAppPopup__title">AccessAble</h3>
            <p class="c-mobileAppPopup__subTitle">Available in the ${popupInfo.storeType} store.</p>
          </div>
          <div class="small-6 text-right cell">
            <a href="${popupInfo.appLink}" class="c-button c-button c-button--solid c-button--solidSecondary  c-button--small">Get App</a>
          </div>
        </div>
        <button class="c-mobileAppPopup__close js-mobileAppPopup__close">
          <span class="show-for-sr">Close</span>
            <svg width="10" height="10">
              <use xlink:href="/packs/sprite.svg#close"></use>
            </svg>
        </button>
      </div>
    `;

    const popup = document.createElement('div');
    popup.innerHTML = popupMarkup;
    document.body.appendChild(popup);

  }

  const closeButton = document.querySelector('.js-mobileAppPopup__close');
  const mobilePopup = document.querySelector('.c-mobileAppPopup');

  if (closeButton) closeButton.addEventListener('click', () => {
    mobilePopup.classList.add('hide');
  });

}

export default mobileDetection;
