/* global google */

import $                      from 'jquery';
import slick                  from 'slick-carousel';
import loadGoogleMapsApi      from 'load-google-maps-api';
import mapOptions             from './maps/options';
import zoomControls           from './maps/zoom_controls';
import defaultMarkerImage     from 'images/markers/default@2x.png';
import arrows from './sliders/arrows';

const _globals = {
  mapSelector: '.js-mediaTabMap',
  mapOps: mapOptions,
  api: {
    key: 'AIzaSyCQnfrDrya4bZRzCn4EfBimwPDcbvGh2fo'
  }
};

const _createMap = ($mapSelector = document.querySelector(_globals.mapSelector)) => {

  const thisLocation = {
    lat: parseFloat($mapSelector.dataset.lat),
    lng: parseFloat($mapSelector.dataset.lng)
  };

  const map = new google.maps.Map($mapSelector, {
    ..._globals.mapOps,
    zoom: 10
  });

  const defaultMarker = {
    url: defaultMarkerImage,
    scaledSize: new google.maps.Size(45, 50)
  };

  const marker = new google.maps.Marker({
    size: new google.maps.Size(45, 50),
    icon: defaultMarker,
    position: thisLocation,
    map: map
  });

  zoomControls(_globals.mapSelector, map);
  map.setCenter(marker.getPosition());

};

const _createSlickInstances = () => {

  const $slider = $('.js-mediaTabMain');
  const $sliderNav = $('.js-mediaTabNav');
  const $venueGallery = $('.js-venueGallery');
  const $sectionGallery = $('.js-sectionGallery');

  // remove controls from videos in sliderNav
  $('.js-mediaTabNav video').removeAttr('controls');

  $slider.on('beforeChange', () => {
    // stop all youtube videos playing when changing slides
    $('.js-youtubeVideo').each(function(i) {
      $('.js-youtubeVideo')[i].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
    });
    // stop all HTML5 videos playing when changing slides
    $('.js-mediaTabMain video').each(function() {
      $(this).get(0).pause();
    });

  });

  $slider.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    adaptiveHeight: true,
    focusOnSelect: true,
    rows: 0,
    infinite: false,
    asNavFor: $sliderNav
  });

  $sliderNav.slick({
    ...arrows,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    dots: false,
    adaptiveHeight: true,
    focusOnSelect: true,
    rows: 0,
    asNavFor: $slider,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      }
    ]
  });

  $venueGallery.slick({
    ...arrows,
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: false,
    infinite: false,
    rows: 0,
    responsive: [
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });

  $sectionGallery.slick({
    ...arrows,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    infinite: false,
    rows: 0,
    responsive: [
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });

  const $sectionGalleryImages = $(".c-mediaTabs__sectionGallery .imageContainer");

  $sectionGalleryImages.on('keydown', function(event) {
    var key = event.key;

    if (key === 'Tab') {
      var $sectionGallery = $(this).closest('.js-sectionGallery');
      if (event.shiftKey) {
        $sectionGallery.slick('slickPrev');
      }
      else {
        $sectionGallery.slick('slickNext');
      }
    }
  });

  window.onclick = function (event) {
    if(event.target.id == 'panorama-close' || event.target.id == 'image-popup-close') {
      $venueGallery.slick('refresh');
      $sectionGallery.slick('refresh');
    }
  };
};

const mediaTabs = () => {

  _createSlickInstances();

  if (!document.querySelector(_globals.mapSelector)) return;

  loadGoogleMapsApi(_globals.api).then(googleMaps => {

    window.google = {};
    window.google.maps = googleMaps;
    _createMap();

  }).catch(error => {
    console.error(error);
  });

};

export default mediaTabs;
