import $ from 'jquery';

function searchFilters() {

  // Expand content
  $('.js-filterToggle').on('click', function(e) {
    e.preventDefault();

    var filterToggle = $(this);
    var filterContent = filterToggle.nextAll(".js-searchFilterContent:first");
    var filterContentAll = $(".js-searchFilterContent");

    if ( filterContent.hasClass("hide") ) {
      filterContentAll.addClass('hide');
      filterContent.removeClass("hide");
    } else {
      filterContentAll.addClass("hide");
    }

    if (filterToggle.attr("aria-expanded") == "true") {
      filterToggle.attr("aria-expanded", "false");
    } else {
      filterToggle.attr("aria-expanded", "true");
    }

    filterToggle.toggleClass('isActive');
  });

  // Saved Accessibility Symbols
  if (!document.querySelector('.c-searchFilter__list input[data-saved="true"]')) {
    $(".js-useSavedSymbols").addClass('hide');
  } else {
    $(".js-useSavedSymbols").on("click", function(e) {
      e.preventDefault();
      $('.c-searchFilter__list').find('input[data-saved="true"]').prop("checked", true);
      $(this).prop('disabled', true);
      $(this).attr('aria-hidden', true);
      $(this).html('Saved Accessibility Symbols Applied')
    });
  }

  // Close filters
  $('.js-filterClose').on('click', function (e) {
    e.preventDefault();
    $(".js-searchFilterContent").addClass('hide');
  });

  // Symbols
  var $symbolCheckboxes = $(".js-searchFilterCheckbox__symbol");

  $symbolCheckboxes.change(function () {
    var length = $symbolCheckboxes.filter(":checked").length;

    let labelText = `${length} selections`;
    if (length === 1) labelText = labelText.slice(0, -1);
    if (length === 0) labelText = "0 selections";

    $(".c-searchFilter--symbol")
      .find(".o-filterBar__selection")
      .text(labelText);
  });

  $('.js-clearSymbols').on('click', function (e) {
    e.preventDefault();
    var savedSymbolsButton = $(".js-useSavedSymbols");

    $("#filter_accessSymbols")
      .find("input")
      .prop("checked", false);

    savedSymbolsButton.prop("disabled", false);
    savedSymbolsButton.attr("aria-hidden", false);
    savedSymbolsButton.html("Use your Saved Accessibility Symbols");

    $(".c-searchFilter--symbol")
      .find(".o-filterBar__selection")
      .text("0 selections");


  });



  // Venues

  var $venueCheckboxes = $(".js-searchFilterCheckbox__venue");

  $venueCheckboxes.change(function() {
    var length = $venueCheckboxes.filter(":checked").length;

    let labelText = `${length} selections`;
    if (length === 1) labelText = labelText.slice(0, -1);
    if (length === 0) labelText = "0 selections";

    $(".c-searchFilter--venue")
      .find(".o-filterBar__selection")
      .text(labelText);
  });

  $(".js-clearVenues").on("click", function(e) {
    e.preventDefault();

    $("#filter_venueTypes")
      .find("input")
      .prop("checked", false);

    $(".c-searchFilter--venue")
      .find(".o-filterBar__selection")
      .text("0 selections");
  });



  // distance
  var $distanceRadios = $(".js-searchFilter__distance");

  $distanceRadios.change(function() {
    var length = $distanceRadios.filter(":checked").val();

    let labelText = `${length} miles`;

    $(".o-filterBar__distance").text(labelText);
  });

}

export default searchFilters;
