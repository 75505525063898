import $ from 'jquery';

function recaptchaEnter() {

    //Login form
    $("input[id='login_email'], input[id='login_password']").on('keypress', e => {
        if (e.key == 'Enter') {
            e.preventDefault();
            $("input[id='login_submit']").trigger("click");
        }
    });

    //Forgotten password form
    $("input[id='forgotten_email']").on('keypress', e => {
        if (e.key == 'Enter') {
            e.preventDefault();
            $("input[id='forgotten_submit']").trigger("click");
        }
    });

    //Signup form
    $("input[id='signup_firstname'], input[id='signup_lastname'], input[id='signup_email']").on('keypress', e => {
        if (e.key == 'Enter') {
            e.preventDefault();
            $("input[id='signup_submit']").trigger("click");
        }
    });

    //Something Changed pop up
    $("input[id='reporter_name'], input[id='reporter_email'], input[id='reporter_phone'], input[id='what_changed']").on('keypress', e => {
        if (e.key == 'Enter') {
            e.preventDefault();
            $("input[id='somethingChanged_submit']").trigger("click");
        }
    });

    //Mailing list form
    $("input[id='mce-EMAIL'], input[id='mce-FNAME'], input[id='mce-LNAME']").on('keypress', e => {
        if (e.key == 'Enter') {
            e.preventDefault();
            $("input[id='mc-embedded-subscribe']").trigger("click");
        }
    });

    //Searches across the site
    $("input[id='query'], input[id='location_search']").on('keypress', e => {
        if (e.key == 'Enter') {
            e.preventDefault();
            $("input[id='search_button']").trigger("click");
        }
    });
    
    $("input[id='query-footer'], input[id='location_search_footer']").on('keypress', e => {
        if (e.key == 'Enter') {
            e.preventDefault();
            $("input[id='search_button_footer']").trigger("click");
        }
    });
    
    $("input[id='organisation_search']").on('keypress', e => {
        if (e.key == 'Enter') {
            e.preventDefault();
            $("input[id='search_button_organisation']").trigger("click");
        }
    });
}

export default recaptchaEnter
