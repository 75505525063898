// Cap height
// Stop the height of an element exceeding the viewport

const capHeightSelector = '.js-capHeight';

// Public: Our primary method to apply our cap height to
const capHeight = () => {

  // Loop all of the instances of the hook
  document.querySelectorAll(capHeightSelector).forEach((capHeightElement => {

    _checkHeight(false, capHeightElement);
    capHeightElement.addEventListener('scroll', _checkHeight);
  }));

  window.addEventListener('resize', _runCheckHeightOnAll, false);
};

// Private: Check all of our cap-height selectors
const _runCheckHeightOnAll = () => {

  document.querySelectorAll(capHeightSelector).forEach((capHeightElement => {
    _checkHeight(false, capHeightElement);
  }));
};

// Private: Our main method to check the elements scroll position etc.
const _checkHeight = (event, capHeightElement) => {

  // Nicer way to reference the selector
  capHeightElement = (event && event.target) ? event.target : capHeightElement;

  // Get bounds
  const capHeightElementBounds = capHeightElement.getBoundingClientRect();

  // Find the difference
  const diff = window.innerHeight - capHeightElementBounds.top;

  // Set difference as a max-height inline style
  capHeightElement.style.maxHeight = diff + 'px';
};

export default capHeight;
