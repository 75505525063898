import $ from 'jquery';

function imagePopupModal() {

  if ($('#js-imagePopup').length > 0) {

    var ajaxURL = "";
    $('.js-imagePopup__modalTrigger').on("click", function(evt){
      evt.preventDefault();
      ajaxURL = evt.currentTarget.dataset.url;
    });

    $(document).on("keydown", function(evt) {
      if ($(document.activeElement).closest('.js-imagePopup__modalTrigger').length && evt.key === 'Enter') {
        evt.preventDefault();
        ajaxURL = $(document.activeElement).closest('.js-imagePopup__modalTrigger').data('url');
        $('#js-imagePopup').foundation('open');
      }
    });

    $(document).on('open.zf.reveal', "#js-imagePopup", function (e) {
      var $content = $('.js-imagePopup__content');
      if (ajaxURL) {
        $content.html("Loading...");
        $.ajax(ajaxURL).done(function (response) {
          $content.html(response);
        });
      }
    });
  }
}

export default imagePopupModal;
