import $ from 'jquery';

function mobilePrimaryNavigation() {

  $('.js-menuToggle').on('click', function(evt) {
    evt.preventDefault();

    $('.js-navigation').toggleClass('is-active');
    $('.c-head').toggleClass('is-fixed');

    $(window).on('resize', function () {
      if ($(this).width() >= 1024) {
        $('.js-navigation').removeClass('is-active');
        $('.c-head').removeClass('is-fixed');
      }
    });
  });

}

export default mobilePrimaryNavigation;
