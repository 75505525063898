const _settings = {
  baseClass: 'o-slider',
  arrows: {
    prev: {
      fullName: 'previous',
      shortName: 'prev',
      icon: 'chevron-right'
    },
    next: {
      fullName: 'next',
      icon: 'chevron-right'
    }
  }
};

const _arrowMarkup = (arrow, baseClass = _settings.baseClass) => {

  const shortName = arrow.shortName || arrow.fullName;

  let buttonClass = `${baseClass}__arrow`;
  buttonClass += ` ${baseClass}__${shortName}`;

  return `
    <button class="${buttonClass}" aria-label="Go to ${arrow.fullName} slide" data-dir="${shortName}">
      <svg class="${baseClass}__icon" width="25" height="25">
        <title>Go to ${arrow.fullName} slide</title>
        <use xlink:href="/packs/sprite.svg#${arrow.icon}"></use>
      </svg>
    </button>
  `;
};

const arrows = {
  prevArrow: _arrowMarkup(_settings.arrows.prev),
  nextArrow: _arrowMarkup(_settings.arrows.next),
  arrows: true
};

export default arrows;
