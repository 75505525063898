import $      from 'jquery';
import slick  from 'slick-carousel';

const promotionsCarousel = () => {
  const $slider = $('.js-promotionsCarousel');
  const $sliderNav = $('.js-promotionsCarouselNav');

  $slider.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    adaptiveHeight: true,
    focusOnSelect: true,
    asNavFor: $sliderNav,
    infinite: false,
    rows: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          arrows:false
        }
      }
    ]
  });
  $sliderNav.slick({
    arrows:true,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    rows: 0,
    focusOnSelect: true,
    asNavFor: $slider,
    infinite: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 0,
        arrows:false,
        settings: "unslick"
      }
    ]
  });

};

export default promotionsCarousel;
