function easyRead() {
  const accessGuideContainer = document.querySelector('.c-accessGuide'),
        easyReadItems = document.querySelectorAll('[data-easy-read]');

  if (easyReadItems.length > 0) {

    document.querySelector(".c-toggleSwitch").classList.remove("hide");

    document.querySelector(".js-easyRead").addEventListener("change", function() {
      const items = accessGuideContainer.querySelectorAll("li");

      if (this.checked) {
        items.forEach(item => {
          item.classList.add("hide");
        });
        easyReadItems.forEach(easyReadItem => {
          easyReadItem.classList.remove("hide");
        });
      } else {
        items.forEach(item => {
          item.classList.remove("hide");
        });
      }

    });
  }
}

export default easyRead;
